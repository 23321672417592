import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Wrapper } from '../reusable'
import { IsMobile } from '../layout'

function LoaBanner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                {IsMobile() ? (
                    <h1>
                        Review and Sign the <br />
                        LOA Document
                    </h1>
                ) : (
                    <h1> Review and Sign the Letter of Authority Document</h1>
                )}
                <p>
                    Please read and sign your 'Letter of Authority Document' to continue your claim with Sentinel Legal
                    Ltd.
                </p>
            </Wrapper>
        </div>
    )
}

export default LoaBanner
