import styles from '../globals/pages.module.scss'
import { useEffect } from 'react'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { apiErrors } from '../helpers/apiErrors'

export default function Unsub() {
    let [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        Loading.standard('Unsubscribing...')
        const token = searchParams.get('id')

        axios
            .post(`${process.env.REACT_APP_API_TOKEN_URL}/unsubscribe-emails`, { token })
            .then((response) => {
                const responseData = response.data
                if (responseData == 'success') {
                    Report.success(
                        'Unsubscribed',
                        'You have been unsubscribed to the Sentinel Legal mailing list.',
                        'Okay',
                        () => {
                            window.location.href = 'https://sentinellegal.co.uk'
                        }
                    )
                }
                Loading.remove()
            })
            .catch((e) => {
                console.error('error:', e)
                const serverError = e.response.data
                Loading.remove()
                apiErrors(serverError)
            })
    }, [])

    return <div className={`${styles.pageContent} ${styles.pocContent}`}></div>
}
